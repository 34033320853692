<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          class="btn"
          @click="$router.push({ name: 'orders-items-create' })"
        >
          Creer un element
        </button>
      </div>
    </div>
    <hr />
    <div class="row justify-content-between">
      <div class="col-xl col-12 py-xl-0 py-2 d-flex">
        <div class="col-auto">Total Quantite :</div>
        <div class="col">
          <span
            v-if="numberItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ numberItems }}
          </span>
          Élément
        </div>
      </div>

      <div class="col-xl col-12 py-xl-0 py-2 d-flex">
        <div class="col-auto">Quantité totale :</div>
        <div class="col">
          <span
            v-if="totalQuantityItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalQuantityItems }}
          </span>
        </div>
      </div>

      <div class="col-xl col-12 py-xl-0 py-2 d-flex">
        <div class="col-auto">Montant Total (TTC*) :</div>
        <div class="col">
          <span
            v-if="totalPriceTTCItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalPriceTTCItems }}
          </span>
          DH
        </div>
      </div>
    </div>
    <hr />
    <br />
    <div class="row">
      <div class="col-xl-3 col-12">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select v-model="order.customer_reference" class="form-select">
          <option value=""></option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            {{ customer.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Date de commande<span class="text-danger">*</span></label>
        <input type="date" v-model="order.date" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for=""> Montant payé <span class="text-danger">*</span></label>
        <input type="text" v-model="order.amountPaid" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Mode de paiement</label>
        <select v-model="order.paymentMethod" class="form-select">
          <option value="Espèce">Espèce</option>
          <option value="Virement bancaire">Virement bancaire</option>
          <option value="Chèque">Chèque</option>
          <option value="Carte bancaire">Carte bancaire</option>
        </select>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-xl-3 col-12">
        <label for="">Date de paiement</label>
        <input type="date" v-model="order.paymentDate" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Numéro de chèque</label>
        <input type="text" v-model="order.checkNumber" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Numéro de virement bancaire</label>
        <input
          type="text"
          v-model="order.bankTransferNumber"
          class="form-control"
        />
      </div>
    </div>

    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-xl-8 col-12">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à la commande (Client,
            Quantité totale, Mantant totale, Nombre d'articles, ...) et
            correctes. <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-xl-auto col-12">
        <button
          class="btn btn-success"
          @click="save(order)"
          :disabled="!accept ? true : false"
        >
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        (<span class="text-danger">*</span>) : Ce champ est obligatoire.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      order: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],

        paymentDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        amountPaid: 0,
      },
      accept: false,
    };
  },
  computed: {
    ...mapGetters("order", {
      order_items: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("order/store", data);
      await setTimeout(((this.order = {}), (this.accept = false)), 2000);
    },
    async refresh() {
      this.$store.dispatch("order/getAllItems");
    },
  },
  async beforeMount() {
    await this.$store.dispatch("order/getAllItems");
    await this.$store.dispatch("customer/getAll");
    (await !this.numberItems)
      ? (await this.$store.dispatch("notification/store", {
          status: "error",
          message: "Aucun élément n'est ajouté. Veuillez en ajouter.",
        }),
        await this.$router.push({ name: "orders-items-create" }))
      : "";
  },
};
</script>
